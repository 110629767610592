<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ activeStep.pageTitle }}</h3>
      </AppCardHeader>
    </template>
    <div>
      <div v-if="isLoadingOptions" class="text-center text-orange">
        <AppSpinner :size="32" :loading="isLoadingOptions"></AppSpinner>
      </div>
      <div v-else>
        <div class="text-gray">
          <p class="text-sm">
            Tell us about a few of your preferences and we can tailor your new account to your exact
            needs.
          </p>
        </div>
        <form class="pt-8" @submit.prevent="submit">
          <AppDynamicForm :fields="formFields" :disabled="isLoading" />
          <AppCardFooter>
            <AppButton type="submit" :disabled="isSubmitButtonDisabled" :loading="isLoading">
              Continue
            </AppButton>
          </AppCardFooter>
        </form>
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/components/AppCard/AppCard'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import AppButton from '@/components/AppButton/AppButton'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppSpinner from '@/components/AppSpinner/AppSpinner'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '../../stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/stores/app'
import { setVolumeLabel } from '@/helpers/businessInformation'

export default {
  components: {
    AppCard,
    AppButton,
    AppCardFooter,
    AppDynamicForm,
    AppCardHeader,
    AppIcon,
    IconChevronLeft,
    AppSpinner,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()

    let isLoadingOptions = ref(true)
    const router = useRouter()
    const store = useStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const fieldGroupName = 'company'
    // Previously ignored fields are added here;
    const fieldsToInclude = ['currencytype', 'expectedannualtradingvolume', 'expectedtotradein']

    const country = companyDetailsStore.companyCountry
    corporateRegistrationStore.fetchCurrencyTypeList({ country })

    corporateRegistrationStore.setActiveStepPageTitle('Business requirements')
    const activeStep = corporateRegistrationStore.activeStep

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    const { currencyTypeList, businessSameOperatingAddress } = storeToRefs(
      corporateRegistrationStore
    )

    let { fields } = corporateRegistrationStore.getNewCustomerFields(fieldGroupName)
    fields = fields.filter(({ id }) => fieldsToInclude.includes(id))
    fields = fields.map((field) => {
      if (field.id === 'expectedannualtradingvolume') {
        // set currency amount label
        field.label = setVolumeLabel(country, field.label)
      }
      return field
    })

    watch(currencyTypeList, () => {
      if (fields) {
        setupFields(fields, fieldGroupName)
        isLoadingOptions.value = false
      }
    })

    const getFieldValue = (id) => {
      const field = formFields.value.find((field) => field.id === id)
      if (field && field.value) {
        return field.value
      }
      return ''
    }

    const getFieldOptionText = (id, toFind, toReturn) => {
      const field = formFields.value.find((field) => field.id === id)
      const selectedOptionText = Object.values(field.props.options).map((option) => {
        if (option[toFind] === getFieldValue(id)) {
          return option[toReturn]
        }
      })
      return selectedOptionText.filter(Boolean).toString()
    }

    const isSubmitButtonDisabled = computed(() => {
      return !hasFormFields.value || isFormInvalid.value
    })

    const goBack = () => {
      if (businessSameOperatingAddress.value) {
        router.replace({ name: 'OperatingAddress' })
      } else {
        router.replace({ name: 'BusinessAddress' })
      }
    }

    const submit = async () => {
      isLoading.value = true

      try {
        // Send data to server
        const data = await corporateRegistrationStore.submitFieldData({
          group: fieldGroupName,
          formFields,
        })

        // Check error
        if (data && data.length > 0) {
          corporateRegistrationStore.showServerErrors({
            data,
            formFields,
          })
        } else {
          // Segment/Amplitude event
          analyticsStore.identify({
            userId: authStore.profileId,
            traits: {
              currency: getFieldValue('currencytype'),
              amount: getFieldOptionText('expectedannualtradingvolume', 'volume', 'text'),
              firstTradeEstimate: getFieldValue('expectedtotradein'),
            },
          })

          analyticsStore.track({
            event: 'Business Requirements Completed',
          })
        }
        // Steps
        router.push({ name: 'RegisterPersonalDetails' })
      } catch (error) {
        appStore.logException({
          text: 'Exception during submiting currency and volume.',
          exception: error,
        })
        appStore.messageBoxGenericError()
      }
      isLoading.value = false
    }

    return {
      isLoading,
      formFields,
      submit,
      mq: reactive(useMediaQuery()),
      isSubmitButtonDisabled,
      activeStep,
      isLoadingOptions,
      goBack,
    }
  },
}
</script>

<style lang="scss" scoped></style>
